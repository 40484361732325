import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"

type SEOMeta =
  | {
      name: string
      content: string
      property?: undefined
    }
  | {
      property: string
      content: string
      name?: undefined
    }

interface Props {
  description: string
  lang: string
  meta: SEOMeta[]
  // jsonLd?: (OrganizationLD | LocalBusinessLD)[]
  title: string
  pageImage: string
  pageUrl: string
}

const SEO = ({
  description,
  lang,
  meta,
  title,
  pageImage,
  pageUrl,
}: Props): JSX.Element => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            author
          }
        }
      }
    `
  )

  const testTitle = `${title} - ${site.siteMetadata.title}`

  if (testTitle.length > 60) {
    throw new Error(
      `Bad title length should be less than 60 is ${testTitle.length} "${testTitle}"`
    )
  }

  if (description.length < 50 || description.length > 160) {
    throw new Error(
      `Bad description length should be 50-160 is ${description.length}`
    )
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defer={false}
      title={title}
      titleTemplate={`%s - ${site.siteMetadata.title}`}
      link={[
        {
          href: `${site.siteMetadata.siteUrl}${pageUrl}`,
          rel: "canonical",
        },
      ]}
      script={[
        {
          type: "text/javascript",
          innerHtml: "var foo = 1;",
        },
      ]}
      meta={[
        {
          name: "description",
          content: description,
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:url",
          content: `${site.siteMetadata.siteUrl}${pageUrl}`,
        },
        {
          property: "og:image",
          content: `${site.siteMetadata.siteUrl}${pageImage}`,
        },
        {
          property: "og:description",
          content: description,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:creator",
          content: site.siteMetadata.author,
        },
        {
          name: "twitter:title",
          content: title,
        },
        {
          name: "twitter:image",
          content: `${site.siteMetadata.siteUrl}${pageImage}`,
        },
        {
          name: "twitter:description",
          content: description,
        },
      ].concat(meta)}
    >
      {/* {jsonLd ? (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": {
              "@vocab": "http://schema.org/",
            },
            "@graph": jsonLd,
          })}
        </script>
      ) : (
        ""
      )} */}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: "fi",
  meta: [],
}

SEO.propTypes = {
  description: PropTypes.string.isRequired,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  pageImage: PropTypes.string.isRequired,
  pageUrl: PropTypes.string.isRequired,
}

export default SEO
