import React from "react"

export interface ContainerProps {
  children: JSX.Element | JSX.Element[]
  backgroundClassName?: string
}

export default ({
  children,
  backgroundClassName,
}: ContainerProps): JSX.Element => (
  <div className={`${backgroundClassName} overflow-hidden`}>
    <div className="relative max-w-7xl mx-auto py-20 px-4 sm:px-6 lg:px-8">
      {children}
    </div>
  </div>
)
